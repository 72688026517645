<template>
	<v-row>
		<v-col cols="12" md="12" v-if="permissao('departamento_pessoal_dashboard_estatisticas_filtro')">
			<v-card>
				<v-card-text>
					<!-- Título da dash, o gerente vai poder acessar o relatório da loja dele -->
					<v-row class="mb-n10">
						<v-col class="px-2 d-flex">
							<v-autocomplete
								:items="filiais.lista"
								clearable
								item-text="filial"
								item-value="idfilial"
								label="Filial"
								outlined
								dense
								:disabled="carregando"
								v-model="busca.idfiliais"
								multiple
								class="d-none d-md-block"
								@change="listar()"
							>
								<template v-slot:selection="{ item, index }">
									<template v-if="index === 0">{{ item.filial }}</template>
									<span
										v-if="index === 1"
										class="grey--text text-caption ml-1"
									>(+{{ busca.idfiliais.length - 1 }})</span>
								</template>
							</v-autocomplete>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" md="12">
			<v-row class="pa-2">
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Idade (Média)">
						<template v-if="dados.tot">{{retornaAnosMeses(dados.tot.idade)}}</template>
						<template v-else>0 anos</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Homens">
						<template
							v-if="dados.tot"
						>{{dados.tot.homens.toFixed(2)}} % ({{dados.estat.map(v => v.homens).reduce((p, c) => p + c)}})</template>
						<template v-else>0.00 %</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Mulheres">
						<template
							v-if="dados.tot"
						>{{dados.tot.mulheres.toFixed(2)}} % ({{dados.estat.map(v => v.mulheres).reduce((p, c) => p + c)}})</template>
						<template v-else>0.00 %</template>
					</CardDashboard>
				</v-col>
				<v-col class="pa-1">
					<CardDashboard :loading="carregando" title="Tempo de casa (média)">
						<template v-if="dados.tot">{{retornaAnosMeses(dados.tot.anos)}}</template>
						<template v-else>0 anos</template>
					</CardDashboard>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" md="12">
			<v-card>
				<v-expansion-panels v-model="panel">
					<v-expansion-panel active-class="primary white--text">
						<v-expansion-panel-header class="font-weight-bold text-h6">
							Estatísticas por cargo
							<template v-slot:actions>
								<v-icon color="white" class="mr-n6">$expand</v-icon>
								<v-icon>$expand</v-icon>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-card flat rounded="0">
								<v-simple-table v-if="!carregando" dense>
									<thead>
										<tr class="blue-grey lighten-4">
											<th>Cargo</th>
											<th class="text-center">Feminino / Masculino</th>
											<th>Idade</th>
											<th>Tempo médio de casa</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(u, i) in dados.estat" :key="i">
											<td>{{u.cargo}}</td>
											<td class="text-center">
												<v-chip color="red" dark small class="font-weight-bold mx-1 width">
													<v-avatar left>
														<v-icon size="18">mdi-face-woman-outline</v-icon>
													</v-avatar>
													{{u.mulheres}}
												</v-chip>
												<v-chip color="blue" dark small class="font-weight-bold mx-1 width">
													<v-avatar left>
														<v-icon size="18">mdi-face-man-outline</v-icon>
													</v-avatar>
													{{u.homens}}
												</v-chip>
											</td>
											<td>{{retornaAnosMeses(u.idademedia)}}</td>
											<td>{{retornaAnosMeses(u.tempomedio)}}</td>
										</tr>
										<tr class="blue-grey lighten-4">
											<td class="font-weight-bold">TOTAL</td>
											<td class="font-weight-bold text-center">
												<v-chip color="red" dark small class="font-weight-bold mx-1 width">
													<v-avatar left>
														<v-icon size="18">mdi-face-woman-outline</v-icon>
													</v-avatar>
													<template
														v-if="dados.estat"
													>{{dados.estat.map(v => v.mulheres).reduce((p, c) => p + c)}}</template>
												</v-chip>
												<v-chip color="blue" dark small class="font-weight-bold mx-1 width">
													<v-avatar left>
														<v-icon size="18">mdi-face-man-outline</v-icon>
													</v-avatar>
													<template v-if="dados.estat">{{dados.estat.map(v => v.homens).reduce((p, c) => p + c)}}</template>
												</v-chip>
											</td>
											<td class="font-weight-bold">
												<template v-if="dados.tot">{{retornaAnosMeses(dados.tot.idade)}}</template>
												<template v-else>0 anos</template>
											</td>
											<td class="font-weight-bold">
												<template v-if="dados.tot">{{retornaAnosMeses(dados.tot.anos)}}</template>
												<template v-else>0 anos</template>
											</td>
										</tr>
									</tbody>
								</v-simple-table>
								<v-card-text v-else>
									<v-skeleton-loader class="mx-auto" width="100%" height="200" type="table-tbody"></v-skeleton-loader>
								</v-card-text>
							</v-card>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-card>
		</v-col>
		<v-col cols="6">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title class="d-inline-block col-12 text-truncate">Faixa etária dos colaboradores</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<GraficoBarDashboard
						v-if="dados.tot.qtdidades"
						:dataSeries="[
                            {
                                name: 'Faixa etária',
                                data: dados.tot.qtdidades.map(v => v.quantidade)
                            },
                        ]"
						:categories="dados.tot.qtdidades.map(v => v.idade.replaceAll(' á ', ' à '))"
						:horizontal="false"
						:porcentagem="false"
						:composto="true"
						height="300"
						:distributed="true"
						:dataLabels="{ position : 'bottom', orientation: 'vertical', offsetY : 20 }"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="6">
			<v-card>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-title class="d-inline-block col-12 text-truncate">Tempo de casa</v-card-title>
				<v-card-text v-if="carregando">
					<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<GraficoBarDashboard
						v-if="dados.tot.qtdtempo"
						:dataSeries="[
                            {
                                name: 'Tempo de casa',
                                data: dados.tot.qtdtempo.map(v => v.quantidade)
                            },
                        ]"
						:categories="dados.tot.qtdtempo.map(v => v.tempo.replaceAll(' a ', ' à '))"
						:horizontal="false"
						:porcentagem="false"
						:composto="true"
						height="300"
						:distributed="true"
						:dataLabels="{ position : 'bottom', orientation: 'vertical', offsetY : 20 }"
					/>
					<span v-else>Nenhum registro encontrado</span>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import CardDashboard from "../Widgets/CardDashboard";
import GraficoBarDashboard from "../Widgets/GraficoBarDashboard";

export default {
	name: "DepartamentoPessoalDashboardEstatisticas",
	mixins: [mixinFilial],
	components: {
		GraficoBarDashboard,
		CardDashboard,
	},
	data: () => ({
		carregando: false,
		dados: {},
		filiais: {},
		busca: {
			idfiliais: [],
		},
		panel: 0,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		listar() {
			let idfiliais = null;
			if (this.busca.idfiliais.length > 0) {
				idfiliais = this.busca.idfiliais.join(",");
			}
			this.dados = {};
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dp/estatisticas`, {
					idfilial: idfiliais || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		retornaAnosMeses(valor) {
			let texto = "";
			if (valor < 1) {
				if (Math.trunc(valor * 12)) {
					Math.trunc(valor * 12) >= 2
						? (texto += `${Math.trunc(valor * 12)} meses`)
						: (texto += `${Math.trunc(valor * 12)} mês`);
				} else {
					texto += `Menos de 1 mês`;
				}
			} else {
				let anos = Math.trunc(valor);
				anos >= 2
					? (texto += `${anos} anos`)
					: (texto += `${anos} ano`);
				let meses = valor - anos;
				if (Math.trunc(meses * 12)) {
					Math.trunc(meses * 12) >= 2
						? (texto += ` e ${Math.trunc(meses * 12)} meses`)
						: (texto += ` e ${Math.trunc(meses * 12)} mês`);
				}
			}
			return texto;
		},
	},
	created() {
		this.busca.idfiliais[0] = this.usuario.idfilial;
		this.listar();
	},
};
</script>

<style scoped>
.width {
	min-width: 70px;
}
</style>